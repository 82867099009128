<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_passenger')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.fullName"
                                          :label="$t('full_name')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-radio-group v-model="customerData.gender" row :label="$t('gender')">
                                <v-radio :label="$t('male')" value="Male"></v-radio>
                                <v-radio :label="$t('female')" value="Female"></v-radio>
                                <v-radio :label="$t('others')" value="Other"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.dateOfBirth"
                                                  :label="$t('dob')"
                                                  clearable
                                                  dense
                                              outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.nationality"
                                          :label="$t('nationality')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.country"
                                            :items="countries"
                                            :label="$t('country')"
                                            dense
                                              outlined
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"
                                            @change="getState"></v-autocomplete>
                        </v-col>

                        <!--<v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.state"
                                            :items="state"
                                            label="State"
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"></v-autocomplete>-->
                        <!--</v-col>-->

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.packageName"
                                            :items="Packages"
                                            :label="$t('package')"
                                            dense
                                              outlined
                                            item-text="value"
                                            item-value="id"></v-autocomplete>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu4"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.travelDate"
                                                  :label="$t('travel_date')"
                                                  clearable
                                                  dense
                                              outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.travelDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.ticketNumber"
                                          :label="$t('ticket_number')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <!--<v-col cols="12"
           sm="6" md="4">
        <v-autocomplete v-model="customerData.ageGroup" :items="AgeGroup"
                        item-text="id"
                        item-value="value"
                        label="Age Group"></v-autocomplete>
    </v-col>

    <v-col cols="12"
           sm="6" md="4">
        <v-autocomplete v-model="customerData.passengerType" :items="PassengerTypes"
                        label="Customer Type"></v-autocomplete>
    </v-col>-->


                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.activation" :items="Activation" item-text="value"
                                            item-value="id"
                                            dense
                                              outlined
                                            :label="$t('activation')"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.destination" :items="Destination" item-text="value"
                                            item-value="value"
                                            :label="$t('destination')"
                                            return-object
                                            dense
                                              outlined
                                            @change="destinationChanged"></v-autocomplete>
                        </v-col>
                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.duration" :items="Duration" item-text="value"
                                            item-value="id"
                                            dense
                                              outlined
                                            :label="$t('duration')"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.serviceName"
                                            :items="ServiceName"
                                            :label="$t('service')"
                                            item-text="value"
                                            dense
                                              outlined
                                            item-value="id"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.citizenShipNumber"
                                          :label="$t('citizenshipNumber')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.passportNumber"
                                          :label="$t('passport_number')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.licenseNumber"
                                          :label="$t('licenseNumber')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.otherID"
                                          :label="$t('otherID')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.document"
                                          :label="$t('documents')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-switch class="col-sm-6 col-md-4"
                                      v-model="customerData.isVerifiedDocument"
                                      :label="$t('is_verified_document')"
                                      clearable></v-switch>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-switch class="col-sm-6 col-md-4"
                                      v-model="customerData.isGroup"
                                      :label="$t('is_group_trekking')"
                                      clearable></v-switch>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.issuedBy"
                                          :label="$t('issued_by')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.issuedDate"
                                                  :label="$t('issued_date')"
                                                  clearable
                                                  dense
                                              outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.issuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="editFormSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "HelicopterCustomerEditForm",
        data() {
            return {
                customerPostData: {},
                customerDestination: '',
                Activation: [],
                Duration: [],
                Destination: [],
                AgeGroup: [],
                customerData: {},
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                    dateMenu4: false,
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                ServiceName: [],
                Packages: [],
                PassengerTypes: ['Adult', 'Child', 'Old'],
                FlightClasses: ['a'],
                Issuer: ['E-Sewa'],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            }
        },
        watch: {
            "customerData.country": {
                handler: async function (val) {
                    if (val) {
                        const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                        this.state = stateList.data
                    }
                },
                deep: true
            }
        },
        props: ['editItemIndex']
        ,
        computed: {},
        methods: {
            async destinationChanged({id, value}) {
                console.log('this.customerPostData', value)
                const {data} = await axios.get('Trekking/GetTrekkingDestinationByIDAsync/' + id)
                this.customerData.permitIssued = data.isPermitRequired
                this.customerDestination = value
            },
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },

            editFormSubmit() {
                this.$delete(this.customerData, 'createdBy')
                this.customerData.IsImportFromExcel = false
                this.customerData.issuedDate = this.customerData.issuedDate == "" ? null : this.customerData.issuedDate
                this.customerPostData = this.customerData
                this.customerPostData.destination = this.customerDestination
                this.customerPostData.age = parseInt(this.customerPostData.age)
                axios.post('Trekking/UpdateCustomerInformationAsync', this.customerPostData)
                    .then((response) => {
                        if (response.data.success) {
                            this.message.status = true
                            this.servicesData.data = response.data
                            this.servicesData.message = "Customer updated Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    })
                    .catch((response) => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    })
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            async formData() {
                axios.get("Shared/CountryList").then(response => {
                    this.countries = response.data;
                }).catch(error => console.log(error.response))
                axios.get('Trekking/DDLTrekkingDestinationByIDAsync').then(response => {
                    this.Destination = response.data
                }).catch(error => console.log(error.response))

                axios.get('Trekking/DDLTrekkingDurationByIDAsync').then(response => {
                    this.Duration = response.data
                }).catch(error => console.log(error.response))

                axios.get('Trekking/DDLTrekkingActivationByIDAsync').then(response => {
                    this.Activation = response.data
                }).catch(error => console.log(error.response))

                axios.get('Trekking/DDLTrekkingServiceListAsync').then(response => {
                    this.ServiceName = response.data
                }).catch(error => console.log(error.response))
                axios.get("Shared/GetAgeGroupList").then(response => {
                    this.AgeGroup = response.data;
                }).catch(error => console.log(error.response))
                axios.get('Trekking/DDLTrekkingPackageListAsync').then(response => {
                    this.Packages = response.data
                }).catch(error => console.log(error.response))

            },
            async getEditData() {
                const {data} = await axios.get('Trekking/GetCustomerInformationByIDAsync/' + this.editItemIndex)
                this.customerData = data

            }
        },
        mounted() {
            this.formData();
            this.getEditData()
        },
    }
</script>

<style scoped>

</style>