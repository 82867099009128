<template>
    <v-content>
        <v-breadcrumbs :items="snackbar.breadcrumbsItem" large></v-breadcrumbs>
        <v-container fluid>
            <v-container>
                <v-row class="float-right">
                    <v-btn @click.stop="onDownload" class="success float-right mx-4">
                        <v-icon>get_app</v-icon>
                        {{$t('download_sample')}}
                    </v-btn>


                    <v-btn @click="importExportModal" class="warning float-right mx-4">

                        {{$t('import')}}
                    </v-btn>
                    <v-dialog width="50%"
                              persistent
                              transition="dialog-bottom-transition"
                              v-model="snackbar.importExportComponent">

                        <ImportExportModal
                                :ieData="ieData"
                                @importExportModalResponse="importExportModalResponse"
                                v-if="snackbar.importExportComponent"
                        ></ImportExportModal>

                    </v-dialog>
                </v-row>
            </v-container>
            <div slot="table-actions">
                <v-btn @click.stop="addModal" color="primary">{{$t('customer_form')}}</v-btn>
            </div>
            <v-dialog max-width="500px" v-model="dialogDelete">
                <deleteListData :deleteFormData="deleteFormData" @formResponse="formResponse"></deleteListData>
            </v-dialog>
            <vue-good-table :columns="columns"
                            :pagination-options="{ enabled: true,mode: 'records',perPageDropdown: [50, 100,200] }"
                            :rows="rows"
                            :totalRows="customerData.totalRecords"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'flightDateTime'">
                        <span>{{props.row.flightDateTime | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                    </span>
                    <span v-else-if="props.column.field == 'actions'">
                        <v-icon @click.stop="editItem(props.row, 'CustomerForm')" class="mr-2" small>edit</v-icon>
                        <v-icon @click.stop="deleteItem(props.row)" small>delete</v-icon>
                    </span>
                </template>
            </vue-good-table>
            <v-dialog fullscreen
                      hide-overlay
                      persistent
                      transition="dialog-bottom-transition"
                      v-model="snackbar.formModal">
                <v-card>
                    <!-- <component :is="snackbar.dynamicComponent.current"
                        @dialogueClose="dialogueClose"
                        @formResponse="formResponse"
                        v-bind="customerData.updateItem"
             ></component>-->
                    <CustomerForm @dialogueClose="dialogueClose"
                                  @formResponse="formResponse"
                                  v-if="snackbar.createFormComponent"></CustomerForm>
                    <CustomerEditForm :editItemIndex="customerData.editItemIndex"
                                      @dialogueClose="dialogueClose"
                                      @formResponse="formResponse"
                                      v-if="snackbar.editFormComponent"></CustomerEditForm>
                </v-card>
            </v-dialog>
            <v-snackbar :timeout="snackbar.timeout"
                        color="success"
                        top
                        v-model="snackbar.snackbar">
                {{ callbackResponse.message }}
                <v-btn @click="snackbar.snackbar = false"
                       dark
                       text>
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import CustomerForm from "./CustomerForm";
    import CustomerEditForm from "./CustomerEditForm";
    import deleteListData from "@/components/deleteModal";
    import ImportExportModal from "@/components/ImportExport/ImportExportModal"

    export default {
        name: "HelicopterCustomerList",
        components: {
            'CustomerForm': CustomerForm,
            'CustomerEditForm': CustomerEditForm,
            deleteListData,
            ImportExportModal

        },
        methods: {
            async onImport() {
                var form = new FormData();

                form.append(
                    "formFile",
                    this.formdata.import
                );
                let ImportResponse = await axios.post("Trekking/Import", form);
                if (ImportResponse.data.success) {
                    this.loadItems();
                    this.formdata.import = null;
                } else {
                    return false;
                }
            },
            importExportModal(){
                this.snackbar.importExportComponent = true
            },
            importExportModalResponse(data){
                this.snackbar.importExportComponent = false
                if(data.modal){
                    this.snackbar.snackbar = true
                    this.callbackResponse.message = data.message
                    this.loadItems();
                }
            },
            async onDownload() {
                await axios({
                    url: 'Trekking/Download',
                    method: 'POST',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ImportTrekkingFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },
            fullName(rowObj) {
                return rowObj.firstName + '   ' + rowObj.lastName
            },
            formResponse(data) {
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            dialogueClose() {
                this.customerData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Country: this.serverParams.columnFilters.country,
                    FirstName: this.serverParams.columnFilters.firstName,
                    CitizenShipNumber: this.serverParams.columnFilters.citizenShipNumber,
                    PassportNumber: this.serverParams.columnFilters.passportNumber,
                    LicenseNumber: this.serverParams.columnFilters.licenseNumber
                };
                axios.post('Trekking/GetCustomerInformationListAsync', param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = item.personInformationID
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Trekking/DeleteCustomerInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.loadItems()
        },
        computed: {
            breadcrumbsItem() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: false,
                        to: 'TrekkingDashboard',
                        exact: true

                    },
                    {
                        text: this.$t('trekking_customer_information'),
                        disabled: true,
                    }
                ]
            }
        },
        data() {
            return {
                ieData:{
                    import:"Trekking/Import",
                },
                callbackResponse: {
                    timeout: 6000
                },
                columns: [

                    {
                        label: "s_no",
                        field: "countIndex"
                    },
                    {
                        label: 'full_name',
                        field: 'fullName'
                    },
                    {
                        label: 'passenger_type',
                        field: 'passengerType',
                    },
                    {
                        label: 'nationality',
                        field: 'nationality',
                    },
                    {
                        label: 'ticket_no',
                        field: 'ticketNumber',
                    },
                    {
                        label: 'issued_date',
                        field: 'issuedDate',
                    },
                    {label: "actions", field: "actions"}
                ],
                rows: [],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                customerData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "personInformationID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    importExportComponent: false,
                    dynamicComponent: {
                        current: 'CustomerForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
